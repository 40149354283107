import { isNewlyCreated } from './helpers'
import { addErrorNotification } from './notificationHelpers'

const rejectWithMessage = (reject, message) => {
  addErrorNotification(message)
  return reject(new Error(message))
}

export const promisifyModifyActions = (dispatch, actions, { updatePrevented = false, createPrevented = false, ...extraData } = {}) => (record, oldRecord) =>
  new Promise((resolve, reject) => {
    if(isNewlyCreated(record)) {
      createPrevented
        ? rejectWithMessage(reject, 'Luominen estetty')
        : dispatch(actions.createRequest(record, { ...extraData, oldData: oldRecord, resolve, reject }))
    } else {
      updatePrevented
        ? rejectWithMessage(reject, 'Päivittäminen estetty')
        : dispatch(actions.updateRequest(record, { ...extraData, oldData: oldRecord, resolve, reject }))
    }
  })

export const promisifyModifyActionsAndNavigate = (dispatch, actions, options) => (record, oldRecord) =>
  promisifyModifyActions(dispatch, actions, options)(record, oldRecord)
    .then(savedRecord => {
      if(isNewlyCreated(record)) {
        dispatch(actions.navigateTo(savedRecord.id, true))
      }
      return savedRecord
    })

const formatActionArguments = (record, oldRecord, extraData, resolve, reject) => [record, { ...extraData, oldData: oldRecord, resolve, reject }]
const formatToolkitActionArguments = (record, oldRecord, extraData, resolve, reject) => [{ ...extraData, record, oldData: oldRecord, resolve, reject }]

const getModifyActions = formatter => (actions, extraData = {}) => (record, oldRecord) =>
  new Promise((resolve, reject) => {
    const { create = null, update = null } = actions
    const args = formatter(record, oldRecord?.oldData ?? oldRecord, extraData, resolve, reject)
    if(isNewlyCreated(record) && create) {
      create(...args)
    } else if(update) {
      update(...args)
    }
  })

export const promisifyBoundModifyActions = getModifyActions(formatActionArguments)

export const promisifyBoundModifyActionsAndNavigate = (actions, options) => (record, oldRecord) =>
  promisifyBoundModifyActions(actions, options)(record, oldRecord)
    .then(savedRecord => {
      if(isNewlyCreated(record)) {
        actions.navigateTo(savedRecord.id, true)
      }
      return savedRecord
    })

export const promisifyAction = (data, boundAction, opts) =>
  new Promise((resolve, reject) =>
    boundAction(data, { ...opts, resolve, reject })
  )

export const promisifyToolkitAction = (record, boundAction, opts) =>
  new Promise((resolve, reject) =>
    boundAction({ ...opts, record, resolve, reject })
  )

export const promisifyToolkitModifyActions = getModifyActions(formatToolkitActionArguments)
export const promisifyToolkitModifyActionsAndNavigate = (actions, options) => (record, oldRecord) =>
  promisifyToolkitModifyActions(actions, options)(record, oldRecord)
    .then(savedRecord => {
      if(isNewlyCreated(record)) {
        actions.navigateTo(savedRecord.id, { replaceUrl: true })
      }
      return savedRecord
    })

export const promisifyUnboundAction = (dispatch, action, data, opts) =>
  new Promise((resolve, reject) =>
    dispatch(action(data, { ...opts, resolve, reject }))
  )

export const promisifyUnboundToolkitAction = (dispatch, action, record, opts) =>
  new Promise((resolve, reject) =>
    dispatch(action({ ...opts, record, resolve, reject }))
  )

export const promisifyDeleteAction = (dispatch, actions, itemToDelete, opts) =>
  new Promise((resolve, reject) => dispatch(actions.deleteRequest(itemToDelete, { ...opts, resolve, reject })))
    .then(data => {
      dispatch(actions.navigateToBase(true))
      return data
    })

export const promisifyBoundDeleteAction = (deleteAction, navigateToBase, opts) => itemToDelete =>
  promisifyAction(itemToDelete, deleteAction, opts)
    .then(data => {
      navigateToBase?.(true)
      return data
    })

export const promisifyToolkitDeleteAction = (deleteAction, navigateToBase, opts) => itemToDelete =>
  promisifyToolkitAction(itemToDelete, deleteAction, opts)
    .then(data => {
      navigateToBase?.(true)
      return data
    })
