import { useEffect, useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import isFunction from 'lodash/isFunction'
import isPlainObject from 'lodash/isPlainObject'

export const getLocalStorageValueOrDefault = <T>(key: string, defaultValue: T): T extends () => infer U ? U : T => {
  const stored = localStorage.getItem(key)
  let isExpired = false

  if(stored) {
    isExpired = clearIfExpired(key)
  }

  if(!stored || isExpired) {
    return isFunction(defaultValue)
      ? defaultValue()
      : defaultValue
  }
  return JSON.parse(stored)
}

export const setToLocalStorage = <T>(key: string, value: T) => {
  if(isPlainObject(value) && !isEmpty(value)) {
    // CreatedAt timestamp used for clearing localStorage items (e.g. pagination pageIndex)
    // @ts-expect-error no need to type check this - it has worked since the dawn of time
    value.__createdAt = Date.now()
  }
  localStorage.setItem(key, JSON.stringify(value))
}

export const useKeepInLocalStorage = <T>(key: string, value: T) => {
  useEffect(() => {
    setToLocalStorage(key, value)
  }, [key, value])
}

/**
 *
 * defaultValue value or function which will be run only if localStorage is empty
 */
export const useLocalStorage = <T>(key: string, defaultValue: T) => {
  const [value, setValue] = useState(
    () => getLocalStorageValueOrDefault(key, defaultValue)
  )
  useKeepInLocalStorage(key, value)
  return [value, setValue] as const
}

export const clearExpiredLocalStorageItemByKey = (key: string, expirationTimeMs: number) => {
  const stored = localStorage.getItem(key)

  if(!stored) {
    return false
  }

  const storedValue = JSON.parse(stored)

  if(!storedValue?.__createdAt) {
    return false
  }

  if(storedValue.__createdAt < (Date.now() - expirationTimeMs)) {
    localStorage.removeItem(key)
    return true
  }

  return false
}

export const clearAllExpiredLocalStorageItems = () => {
  Object.keys(localStorage).forEach(key => {
    clearIfExpired(key)
  })
}

const clearIfExpired = (key: string) => {
  const expirationSettings = {
    data_table_pagination_index: 1000 * 60 * 60 * 4 // 4 hours
  }

  let isExpired = false

  for(const [keyPattern, expirationTime] of Object.entries(expirationSettings)) {
    if(key.startsWith(keyPattern)) {
      isExpired = clearExpiredLocalStorageItemByKey(key, expirationTime)
    }
  }

  return isExpired
}
