import { emptyArray } from '@evelia/common/constants'
import { sortByProperty } from '@evelia/common/helpers'
import {
  PacketIndustryModel,
  PricingRuleSetModel,
  SalaryGroupModel,
  SalaryTypeModel,
  WageIncomeTypeModel
} from '@evelia/common/types'

import { mapRecordNameOptions } from '../helpers/helpers'
import { createArgumentSelector, createEveliaSelector, getFilterItemsByFieldSelector, getFindItemByIdSelector } from '../helpers/typedSelectorHelpers'
import { RootState } from '../reducerTypes'
import { getActiveProductLines } from './productLineSelectors'

export const getSalaryTypesFromArgument = (arg: RootState | SalaryTypeModel[]) => Array.isArray(arg) ? arg : arg.systemCustomer.salaryTypes.records
export const getPacketIndustriesFromArgument = (arg: RootState | PacketIndustryModel[]) => Array.isArray(arg) ? arg : arg.systemCustomer.packetIndustries.records
export const getSalaryGroupsFromArgument = (arg: RootState | SalaryGroupModel[]) => Array.isArray(arg) ? arg : arg.systemCustomer.salaryGroups.records
export const getWageIncomeTypesFromArgument = (arg: RootState | WageIncomeTypeModel[]) => Array.isArray(arg) ? arg : arg.systemCustomer.wageIncomeTypes.records
export const getPricingRuleSetsFromArgument = (arg: RootState | PricingRuleSetModel[]) => Array.isArray(arg) ? arg : arg.systemCustomer.pricingRuleSets.records

const sortByName = sortByProperty('name')

export const mapPricingRulesWithProductLines = createArgumentSelector(
  [
    state => getActiveProductLines(state),
    state => state.systemCustomer.pricingRules.records,
    (state, pricingRuleSetId) => pricingRuleSetId === null ? null : Number(pricingRuleSetId)
  ],
  (productLines, pricingRules, pricingRuleSetId) =>
    productLines.map(productLine => pricingRules.find(pricingRule => pricingRule.productLineId === productLine.id && pricingRule.pricingRuleSetId === pricingRuleSetId) ||
      { id: 'temp_' + productLine.id + '_' + pricingRuleSetId, productLineId: productLine.id, pricingRuleType: 1, pricingRuleSetId })
)

export const getPricingRuleSetOptions = createEveliaSelector(
  [
    state => state && state.systemCustomer ? state.systemCustomer.pricingRuleSets.records : state
  ],
  pricingRuleSets => [{ value: null, text: 'Oletus' }, ...pricingRuleSets.map(pricingRuleSet => ({ value: pricingRuleSet.id, text: pricingRuleSet.name }))]
)

export const findPricingRuleSetById = getFindItemByIdSelector(getPricingRuleSetsFromArgument)

export const findPricingRuleFallbackWithSetId = createArgumentSelector(
  state => state.systemCustomer.pricingRuleFallbacks.records,
  (state, pricingRuleSetId) => pricingRuleSetId === null ? null : Number(pricingRuleSetId),
  (pricingRuleFallbacks, pricingRuleSetId) => pricingRuleFallbacks.find(pricingRuleFallback => pricingRuleFallback.pricingRuleSetId === pricingRuleSetId)
)

export const findSalaryTypeWithId = getFindItemByIdSelector(getSalaryTypesFromArgument)

export const findSalaryTypesWithSystemType = getFilterItemsByFieldSelector(getSalaryTypesFromArgument, ['systemType'])

export const findPacketIndustryWithId = getFindItemByIdSelector(getPacketIndustriesFromArgument)

export const getPacketIndustrySelectOptions = createEveliaSelector(
  getPacketIndustriesFromArgument,
  packetIndustries => mapRecordNameOptions(packetIndustries)
)

export const findSalaryGroupWithId = createArgumentSelector(
  [
    getSalaryGroupsFromArgument,
    (state, salaryGroupId) => Number(salaryGroupId)
  ],
  (salaryGroups, salaryGroupId) => salaryGroups.find(salaryGroup => salaryGroup.id === salaryGroupId)
)

export const findEmployeeSystemCustomers = createEveliaSelector(
  state => state.whoAmI.employeeSystemCustomers || emptyArray,
  employeeSystemCustomers => [...employeeSystemCustomers].sort(sortByName)
)

export const findSystemCustomerSettings = createEveliaSelector(
  [
    state => state.systemCustomer
  ],
  systemCustomer => systemCustomer.settingsData
)

export const getSystemCustomerAnnualInvoicingsByType = createArgumentSelector(
  [
    state => state.systemCustomer.systemCustomerAnnualInvoicings.records,
    (__state, type) => type
  ],
  (systemCustomerAnnualInvoicings, type) => systemCustomerAnnualInvoicings.filter(systemCustomerAnnualInvoicing => systemCustomerAnnualInvoicing.type === type)
)

export const findWageIncomeTypeWithId = getFindItemByIdSelector(getWageIncomeTypesFromArgument)

export const findWageIncomeTypeWithSystemType = getFilterItemsByFieldSelector(getWageIncomeTypesFromArgument, ['systemType'])
