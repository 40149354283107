import { createApi } from '@reduxjs/toolkit/query/react'

import { getBaseQuery } from './apiHelpers'

export const warehouseApi = createApi({
  reducerPath: 'warehouseApi',
  baseQuery: getBaseQuery('warehouses'),
  tagTypes: ['shelves'],
  endpoints: builder => ({
    getWarehouseShelves: builder.query<string[], { warehouseId: number }>({
      query: ({ warehouseId }) => `/${warehouseId}/shelves`,
      providesTags: (__result, __error, arg, __meta) => [{ type: 'shelves', id: arg.warehouseId }]
    })
  })
})

export const {
  useGetWarehouseShelvesQuery
} = warehouseApi
