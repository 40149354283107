import PropTypes from 'prop-types'

import { FloatingIconTooltip } from '../Tooltip/FloatingTooltip'

const SystemMessageSystemDetail = ({ extra }) => {
  return (
    <>
      <FloatingIconTooltip label='Evelia Oy' tooltip='Evelia Oy:n tiedote kaikille asiakkaille' />
      {extra ? <><br />{extra}</> : null}
    </>
  )
}
SystemMessageSystemDetail.propTypes = {
  extra: PropTypes.node
}

export default SystemMessageSystemDetail
