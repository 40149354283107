import { OccupationClassificationModel } from '@evelia/common/types'
import {
  createEntityAdapter
} from '@reduxjs/toolkit'

import { createGenericSlice } from '../helpers/sliceHelpers'
import { EveliaRootState } from '../reducerTypes'

const occupationClassificationAdapter = createEntityAdapter<OccupationClassificationModel>({})

const occupationClassificationSlice = createGenericSlice({
  name: 'occupationClassifications',
  entityAdapter: occupationClassificationAdapter
})

export const occupationClassificationEntitySelectors = occupationClassificationAdapter.getSelectors<EveliaRootState>(state => state.occupationClassifications)
export const occupationClassificationActions = occupationClassificationSlice.actions

export default occupationClassificationSlice
