import { combineSlices, Reducer } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import { RouterState } from 'redux-first-history'

import { deliveryTermApi } from './api/rtk/deliveryTermApi'
import { deliveryTypeApi } from './api/rtk/deliveryTypeApi'
import { exportsApi } from './api/rtk/exportsApi'
import { inboundInvoiceApi } from './api/rtk/inboundInvoiceApi'
import { productWarehouseApi } from './api/rtk/productWarehouseApi'
import { projectApi } from './api/rtk/projectApi'
import { purchaseOrderApi } from './api/rtk/purchaseOrderApi'
import { purchaseOrderFileApi } from './api/rtk/purchaseOrderFileApi'
import { purchaseOrderMemoApi } from './api/rtk/purchaseOrderMemoApi'
import { purchaseOrderRowApi } from './api/rtk/purchaseOrderRowApi'
import { purchaseOrderStateApi } from './api/rtk/purchaseOrderStateApi'
import { supplyOfferApi } from './api/rtk/supplyOfferApi'
import { supplyOfferKanbanApi } from './api/rtk/supplyOfferKanbanApi'
import { ticketsApi } from './api/rtk/ticketsApi'
import { ticketsKanbanApi } from './api/rtk/ticketsKanbanApi'
import { ticketTemplateListApi } from './api/rtk/ticketTemplateListApi'
import { vatCodeApi } from './api/rtk/vatCodeApi'
import { warehouseApi } from './api/rtk/warehouseApi'
import { workApi } from './api/rtk/workApi'
import { workKanbanApi } from './api/rtk/workKanbanApi'
import { workScheduleApi } from './api/rtk/workScheduleApi'
import { actionTypes } from './constants'
import accounts from './reducers/accountReducer'
import authors from './reducers/authorReducer'
import cashFlowForecastExtraValues from './reducers/cashFlowForecastExtraValueReducer'
import cashReceipts from './reducers/cashReceiptReducer'
import configs from './reducers/configReducer'
import contacts from './reducers/contactReducer'
import costCentres from './reducers/costCentreReducer'
import costProvisions from './reducers/costProvisionReducer'
import customerGroups from './reducers/customerGroupReducer'
import customers from './reducers/customerReducer'
import dashboard from './reducers/dashboardReducer'
import denominations from './reducers/denominationReducer'
import eAddresses from './reducers/eAddressReducer'
import employeeLicenceTypes from './reducers/employeeLicenceTypeReducer'
import employees from './reducers/employeeReducer'
import employeeRoles from './reducers/employeeRoleReducer'
import files from './reducers/fileReducer'
import fillOutForm from './reducers/fillOutFormReducer'
import formTemplates from './reducers/formTemplateReducer'
import formTypes from './reducers/formTypeReducer'
import withHistory from './reducers/historyReducer'
import inboundInvoices from './reducers/inboundInvoiceReducer'
import inboundMessages from './reducers/inboundMessageReducer'
import invitations from './reducers/invitationReducer'
import invoices from './reducers/invoiceReducer'
import machineRecords from './reducers/machineRecordReducer'
import machines from './reducers/machineReducer'
import miscellaneous from './reducers/miscellaneousReducer'
import offerCustomStates from './reducers/offerCustomStateReducer'
import offerPostStates from './reducers/offerPostStateReducer'
import offers from './reducers/offerReducer'
import packetMatrices from './reducers/packetMatrixReducer'
import packets from './reducers/packetReducer'
import productGroups from './reducers/productGroupReducer'
import productLines from './reducers/productLineReducer'
import products from './reducers/productReducer'
import projects from './reducers/projectReducer'
import projectTypes from './reducers/projectTypeReducer'
import quickProducts from './reducers/quickProductReducer'
import receipts from './reducers/receiptReducer'
import receivers from './reducers/receiverReducer'
import reports from './reducers/reportReducer'
import errors from './reducers/sagaErrorHandlerReducer'
import suppliers from './reducers/supplierReducer'
import supplyOfferCustomStates from './reducers/supplyOfferCustomStateReducer'
import supplyOffers from './reducers/supplyOfferReducer'
import supplyOfferTypes from './reducers/supplyOfferTypeReducer'
import systemCustomer from './reducers/systemCustomerReducer'
import systemMessages from './reducers/systemMessageReducer'
import targets from './reducers/targetReducer'
import ticketRecurrenceRules from './reducers/ticketRecurrenceRuleReducer'
import tickets from './reducers/ticketReducer'
import ticketStates from './reducers/ticketStateReducer'
import ticketTypes from './reducers/ticketTypeReducer'
import timeRecords from './reducers/timeRecordReducer'
import ui from './reducers/uiReducer'
import warehouses from './reducers/warehouseReducer'
import whoAmI from './reducers/whoAmIReducer'
import workCustomStates from './reducers/workCustomStateReducer'
import workQuickFilters from './reducers/workQuickFilterReducer'
import workRecordProducts from './reducers/workRecordProductReducer'
import workRecords from './reducers/workRecordReducer'
import workRecurrenceRules from './reducers/workRecurrenceRuleReducer'
import work from './reducers/workReducer'
import workTypes from './reducers/workTypeReducer'
import apiCertificateSlice from './slices/apiCertificateSlice'
import employmentSalaryTypeSlice from './slices/employmentSalaryTypeSlice'
import employmentSlice from './slices/employmentSlice'
import federatedCredentialSlice from './slices/federatedCredentialSlice'
import insuranceCompanySlice from './slices/insuranceCompanySlice'
import insuranceSlice from './slices/insuranceSlice'
import occupationClassificationSlice from './slices/occupationClassificationSlice'
import purchaseOrderFileSlice from './slices/purchaseOrderFileSlice'
import purchaseOrderMemoSlice from './slices/purchaseOrderMemoSlice'
import salaryCalculationRowSlice from './slices/salaryCalculationRowSlice'
import salaryCalculationSlice, { salaryCalculationFileSlice } from './slices/salaryCalculationSlice'
import salaryPeriodSlice from './slices/salaryPeriodSlice'
import taxDeductionCardSlice from './slices/taxDeductionCardSlice'
import { vatClassSlice, vatRateSlice } from './slices/vatSlices'
import wageEarnerSlice from './slices/wageEarnerSlice'

const reducer = (routerReducer: Reducer<RouterState>) => combineReducers({
  accounts,
  configs,
  contacts,
  costCentres,
  costProvisions,
  customers,
  dashboard,
  denominations,
  employeeRoles,
  employees,
  employeeLicenceTypes,
  errors,
  files,
  fillOutForm,
  inboundInvoices,
  inboundMessages,
  invitations,
  invoices,
  machineRecords,
  machines,
  miscellaneous,
  offerCustomStates,
  offers,
  offerPostStates,
  packets,
  packetMatrices,
  productLines,
  products,
  projects,
  projectTypes,
  quickProducts,
  receipts,
  receivers,
  reports,
  router: withHistory(routerReducer),
  suppliers,
  systemCustomer,
  systemMessages,
  targets,
  tickets,
  ticketStates,
  ticketTypes,
  timeRecords,
  ui,
  warehouses,
  whoAmI,
  work,
  workRecordProducts,
  workRecords,
  workTypes,
  workCustomStates,
  cashReceipts,
  customerGroups,
  productGroups,
  supplyOffers,
  supplyOfferTypes,
  supplyOfferCustomStates,
  workQuickFilters,
  cashFlowForecastExtraValues,
  eAddresses,
  workRecurrenceRules,
  ticketRecurrenceRules,
  formTypes,
  authors,
  formTemplates,
  [workKanbanApi.reducerPath]: workKanbanApi.reducer,
  [workApi.reducerPath]: workApi.reducer,
  [supplyOfferKanbanApi.reducerPath]: supplyOfferKanbanApi.reducer,
  [supplyOfferApi.reducerPath]: supplyOfferApi.reducer,
  [ticketsApi.reducerPath]: ticketsApi.reducer,
  [ticketsKanbanApi.reducerPath]: ticketsKanbanApi.reducer,
  [insuranceSlice.reducerPath]: insuranceSlice.reducer,
  [insuranceCompanySlice.reducerPath]: insuranceCompanySlice.reducer,
  [occupationClassificationSlice.reducerPath]: occupationClassificationSlice.reducer,
  [inboundInvoiceApi.reducerPath]: inboundInvoiceApi.reducer,
  wageEarners: combineSlices(wageEarnerSlice, taxDeductionCardSlice, employmentSlice, employmentSalaryTypeSlice),
  [federatedCredentialSlice.reducerPath]: federatedCredentialSlice.reducer,
  [ticketTemplateListApi.reducerPath]: ticketTemplateListApi.reducer,
  salaryPeriods: combineSlices(salaryPeriodSlice, salaryCalculationSlice, salaryCalculationRowSlice, salaryCalculationFileSlice.fileLinkSlice),
  [vatClassSlice.reducerPath]: vatClassSlice.reducer,
  [vatRateSlice.reducerPath]: vatRateSlice.reducer,
  [vatCodeApi.reducerPath]: vatCodeApi.reducer,
  [purchaseOrderApi.reducerPath]: purchaseOrderApi.reducer,
  [purchaseOrderRowApi.reducerPath]: purchaseOrderRowApi.reducer,
  [purchaseOrderStateApi.reducerPath]: purchaseOrderStateApi.reducer,
  [purchaseOrderMemoApi.reducerPath]: purchaseOrderMemoApi.reducer,
  [purchaseOrderFileApi.reducerPath]: purchaseOrderFileApi.reducer,
  [productWarehouseApi.reducerPath]: productWarehouseApi.reducer,
  [deliveryTermApi.reducerPath]: deliveryTermApi.reducer,
  [deliveryTypeApi.reducerPath]: deliveryTypeApi.reducer,
  purchaseOrders: combineSlices(purchaseOrderMemoSlice, purchaseOrderFileSlice),
  [exportsApi.reducerPath]: exportsApi.reducer,
  [projectApi.reducerPath]: projectApi.reducer,
  apiCertificates: apiCertificateSlice.reducer,
  [workScheduleApi.reducerPath]: workScheduleApi.reducer,
  [warehouseApi.reducerPath]: warehouseApi.reducer
})

const rootReducer = routerReducer => (state, action) => {
  if(action.type === actionTypes.LOGOUT_SUCCESS || action.type === actionTypes.SWITCH_SYSTEM_CUSTOMER_SUCCESS || action.type === actionTypes.LINK_SYSTEM_CUSTOMER_SUCCESS) {
    state = undefined
  }
  return reducer(routerReducer)(state, action)
}

export default rootReducer
