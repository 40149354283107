import { TaxDeductionCardModel } from '@evelia/common/types'
import {
  createEntityAdapter,
  PayloadAction
} from '@reduxjs/toolkit'

import { createGenericSlice } from '../helpers/sliceHelpers'
import { EveliaRootState } from '../reducerTypes'

const taxDeductionCardAdapter = createEntityAdapter<TaxDeductionCardModel>({})

const taxDeductionCardSlice = createGenericSlice({
  name: 'taxDeductionCards',
  entityAdapter: taxDeductionCardAdapter,
  reducers: {
    doPostAction: (state, __action: PayloadAction) => state
  }
})

export const taxDeductionCardEntitySelectors = taxDeductionCardAdapter.getSelectors<EveliaRootState>(state => state.wageEarners.taxDeductionCards)
export const taxDeductionCardActions = taxDeductionCardSlice.actions

export default taxDeductionCardSlice
