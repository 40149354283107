import { useMemo } from 'react'
import { Badge } from 'reactstrap'
import { inboundInvoiceCostTypes } from '@evelia/common/constants'
import { InboundInvoiceModel } from '@evelia/common/types'
import { faComment, faEuroSign } from '@fortawesome/free-solid-svg-icons'
import { ValueOf } from 'type-fest'

import { FloatingIconTooltip } from '../../components/Tooltip/FloatingTooltip'
import { accountingStatuses, inboundInvoiceStates, vatTypeTitles } from '../../constants'
import { formatEur } from '../../helpers/helpers'

const partialStates = [inboundInvoiceStates.INBOUND_INVOICE_PAYMENT_STATE_WAITING, inboundInvoiceStates.INBOUND_INVOICE_PAYMENT_STATE_PARTIALLY_PAID]

const paymentStateMap = {
  [inboundInvoiceStates.INBOUND_INVOICE_PAYMENT_STATE_WAITING_PERMISSION]: { title: 'Odottaa maksulupaa', color: 'danger' },
  [inboundInvoiceStates.INBOUND_INVOICE_PAYMENT_STATE_WAITING]: { title: 'Odottaa maksatusta', color: 'info' },
  [inboundInvoiceStates.INBOUND_INVOICE_PAYMENT_STATE_PARTIALLY_PAID]: { title: 'Osittain maksettu', color: 'warning' },
  [inboundInvoiceStates.INBOUND_INVOICE_PAYMENT_STATE_PAID]: { title: 'Maksettu', color: 'success' },
  [inboundInvoiceStates.INBOUND_INVOICE_PAYMENT_STATE_PAID_MANUALLY]: { title: 'Maksettu käsin', color: 'success' },
  [inboundInvoiceStates.INBOUND_INVOICE_PAYMENT_STATE_OVERPAID]: { title: 'Ylisuoritettu', color: 'danger' },
  [inboundInvoiceStates.INBOUND_INVOICE_PAYMENT_STATE_FAILED]: { title: 'Epäonnistunut', color: 'danger' }
}

interface PaymentStateBadgeProps {
  inboundInvoiceId: InboundInvoiceModel['id']
  paymentState: InboundInvoiceModel['paymentState']
  unapprovedSum: InboundInvoiceModel['unapprovedSum']
  banksonState: InboundInvoiceModel['banksonState']
}

const PaymentStateBadge = ({ inboundInvoiceId, paymentState, unapprovedSum, banksonState }: PaymentStateBadgeProps) => {
  const getPaymentStateBadge = useMemo(() => {
    const data = { ...(paymentStateMap[paymentState as Exclude<typeof paymentState, null>] ?? { title: paymentState, color: 'danger' }) }
    // @ts-expect-error TODO: Can bankson state be 'waiting_payment'???
    if(banksonState === accountingStatuses.ACCOUNTING_STATUS_WAITING_PAYMENT) {
      // @ts-expect-error invalid types
      data.title = (
        <FloatingIconTooltip
          icon={faEuroSign}
          iconProps={{
            mask: faComment,
            transform: 'shrink-7'
          }}
          tooltip='Vähintään yksi maksuerä odottaa maksupäivää'
        >
          <i>{data.title}</i>
        </FloatingIconTooltip>
      )
    }
    return <Badge color={data.color}>{data.title}</Badge>
  }, [banksonState, paymentState])
  const getPartialPaymentStateBadge = useMemo(() => {
    if((partialStates as string[]).includes(paymentState as Exclude<typeof paymentState, null>) && unapprovedSum !== 0) {
      return <Badge color='danger' title={`${formatEur(unapprovedSum)} ilman maksulupaa`}>Osittainen maksulupa</Badge>
    }
    return null
  }, [paymentState, unapprovedSum])
  return (
    <>
      {getPaymentStateBadge}
      {getPartialPaymentStateBadge}
    </>
  )
}

export const inboundInvoiceStatusBadgeTypes = {
  ARCHIVE: 'archive',
  RECURRING: 'recurring',
  STATE: 'state',
  PAYMENT_STATE: 'paymentState',
  ACCOUNTING_STATE: 'accountingState',
  COST_TYPE: 'costType',
  VAT_TYPE: 'vatType'
} as const

interface InboundInvoiceStatusBadgeProps {
  inboundInvoice: InboundInvoiceModel
  type?: ValueOf<typeof inboundInvoiceStatusBadgeTypes>
}

const InboundInvoiceStatusBadge = ({ inboundInvoice, type = 'state' }: InboundInvoiceStatusBadgeProps) => {
  if(!inboundInvoice) {
    return null
  }

  switch(type) {
    case inboundInvoiceStatusBadgeTypes.ARCHIVE:
      if(inboundInvoice.archivedAt) {
        return <Badge color='info'>Arkistoitu</Badge>
      } else {
        return null
      }
    case inboundInvoiceStatusBadgeTypes.RECURRING:
      if(inboundInvoice.templateInboundInvoiceId) {
        return <Badge color='secondary'>Toistuva</Badge>
      } else {
        return null
      }
    case inboundInvoiceStatusBadgeTypes.STATE:
      if(inboundInvoice.isHold) {
        return <Badge color='warning'>Pidossa</Badge>
      }
      switch(inboundInvoice.state) {
        case inboundInvoiceStates.INBOUND_INVOICE_STATE_RECEIVED:
          return <Badge color='info'>Perustiedot noudettu</Badge>
        case inboundInvoiceStates.INBOUND_INVOICE_STATE_RECEIVED_FULL:
          return <Badge color={inboundInvoice.isValidPayable ? 'info' : 'danger'}>{inboundInvoice.isValidPayable ? 'Käsittelemätön' : 'Puutteellinen'}</Badge>
        case inboundInvoiceStates.INBOUND_INVOICE_STATE_WAITING_APPROVAL:
          return <Badge color='warning'>Odottaa hyväksyntää</Badge>
        case inboundInvoiceStates.INBOUND_INVOICE_STATE_REJECTED:
          return <Badge color='danger'>Hylätty</Badge>
        case inboundInvoiceStates.INBOUND_INVOICE_STATE_APPROVED:
          return <Badge color='success'>Hyväksytty</Badge>
        default:
          return <Badge color='danger'>{inboundInvoice.state}</Badge>
      }
    case inboundInvoiceStatusBadgeTypes.PAYMENT_STATE:
      return (
        <PaymentStateBadge
          inboundInvoiceId={inboundInvoice.id}
          paymentState={inboundInvoice.paymentState}
          unapprovedSum={inboundInvoice.unapprovedSum}
          banksonState={inboundInvoice.banksonState}
        />
      )
    case inboundInvoiceStatusBadgeTypes.ACCOUNTING_STATE:
      switch(inboundInvoice.accountingState) {
        case inboundInvoiceStates.INBOUND_INVOICE_ACCOUNTING_STATE_WAITING:
          return <Badge color='danger'>Tiliöimätön</Badge>
        case inboundInvoiceStates.INBOUND_INVOICE_ACCOUNTING_STATE_PARTIALLY:
          return <Badge color='warning'>Osittain tiliöity</Badge>
        case inboundInvoiceStates.INBOUND_INVOICE_ACCOUNTING_STATE_DONE:
          return <Badge color='success'>Tiliöity</Badge>
        default:
          return <Badge color='danger'>{inboundInvoice.accountingState}</Badge>
      }
    case inboundInvoiceStatusBadgeTypes.COST_TYPE:
      switch(inboundInvoice.costType) {
        case inboundInvoiceCostTypes.INBOUND_INVOICE_COST_TYPE_SUBCONTRACTING:
          return <Badge color='info'>Alihankinta</Badge>
        default:
          return null
      }
    case inboundInvoiceStatusBadgeTypes.VAT_TYPE:
      if(inboundInvoice.vatType) {
        return <Badge color='warning'>{vatTypeTitles[inboundInvoice.vatType]}</Badge>
      } else {
        return null
      }
    default:
      return null
  }
}

export default InboundInvoiceStatusBadge
